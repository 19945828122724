.home-page {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.main-logo {
  position: fixed;
  top: 20px;
  left: 20px;
}
.select-station {
  clear: both;
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.select-station li {
  margin: 20px 30px;
  font-size: 22px;
  font-weight: bold;
}
.select-station-logo {
  display: flex;
  width: 230px;
  height: 140px;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin: 0 auto 15px;
  border-radius: $boxBorderRadius;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $primaryColor;
  font-size: 80px;
}
.select-station-logo img {
  height: 80px;
}
.select-station li .color-1 {
  background: $brightColor1;
}
.select-station li .color-2 {
  background: $brightColor2;
}
.action-box-content small {
  font-size: 12px;
}

.setting-page {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
}
