$background: #359BC0 url(/assets/images/bg.svg) no-repeat;
$fontColor: #fff;

$inputBg: #fff;
$inputColor: #333;
$inputBorder: #ddd;
$inputShadow:  0 3px 6px rgba(0,0,0,0.3);

$buttonShadow:  1px 1px 4px rgba(0,0,0,0.2);

$fontFamily: 'WF Sarabun', sans-serif;
$headerFontFamily: 'WF Prompt', sans-serif;

$primaryColor: #2E99C5;
$primaryTint: #fff;

$primaryLightColor: #309FC8;
$primaryLightColorTint: #ffffff;

$secondaryColor: #92568F;
$secondaryColorTint: #fff;

$blackColor: #4B4B4B;
$blackTint: #fff;

$brightColor1: #3CCE00;
$brightColor2: #6F00B7;

$alertColor: #D7373F;
$infoColor: #127193;
$successColor: #38B42F;

$boxBorderRadius: 20px;