@media print {
	@page {
		margin: 0.8cm 0.8cm;
		overflow: hidden;
		//size: 3.2cm 2.2cm landscape;
	}
	
	.topic, .page-content {
		page-break-inside: avoid;
	}
	.page-content-main {
		height: auto !important;
		padding: 0;
	}

	::-webkit-scrollbar {
		width: 0 !important;
		display: none;
	}

	html,
	body {
		padding: 0;
		margin: 0;
		min-height: 100%;
		height: auto;
		color: #000;
		font-family: sans-serif;
		overflow: visible;
	}

	.switch-wrapper > div {
		position: relative;
	}

	.page-header {
		display: none;
	}

	.sticker-print-list {
		padding: 1cm;
		margin: 0;
		width: 100%;
	}

	.sticker-print-buttons {
		display: none;
	}

	.sticker-list {
		width: 100%;
		box-shadow: none;
		padding: 0;
		margin: 0.5;
		float: none;
		text-align: left;
	}

	.sticker-list>li {
		border: 1px solid #666;
		display: inline-block;
		page-break-inside: avoid;
	}


	.pe-print-display {
		overflow: hidden;
		
	}

	.pe-print-display::-webkit-scrollbar {
		width: 0 !important
	}

	.pe-print-buttons {
		display: none;
	}

	.page-content-main {
		overflow: hidden;
	}

	.mc-print-display {
		font-size: 12px;
		line-height: 1.4em;

		table {
			margin: 0 0 5px;
		}
	}
}