input.form-control {
  background: $inputBg;
  color: $inputColor;
  border: 1px solid $inputBorder;
  box-shadow: $inputShadow;
  font-size: 1.2rem;
  line-height: 2rem;
  padding: 8px 20px 5px;
  display: block;
  width: 100%;
  border-radius: 50px;
  outline: none;
}
.form-table ::-webkit-input-placeholder {
  color: #fff;
  font-size: 16px;
  line-height: 2rem;
}
select.form-control {
  color: $inputColor;
  border: 1px solid $inputBorder;
  box-shadow: $inputShadow;
  font-size: 1.2rem;
  line-height: 2rem;
  padding: 8px 20px 5px;
  display: block;
  width: 100%;
  margin: 0;
  background-color: $inputBg;
  background-image: url("../images/arrow-down.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 20px auto, 100%;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 50px;
}

input.form-control:focus,
input.form-control:active {
  border: 1px solid rgb(52, 139, 233);
  box-shadow: 0 0 2px 2px rgba(52, 139, 233, 0.8);
}

select.form-control::-ms-expand {
  display: none;
}
select.form-control:hover {
}
select.form-control:focus {
}
select.form-control option {
  font-weight: normal;
}
